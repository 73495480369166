.user_card__divider__basic_payment_info {
    grid-area: divider__basic_payment_info;
}

.user_card__stripe_data_customer_id {
    grid-area: stripe_data_customer_id;
}

.user_card__stripe_data_session_id {
    grid-area: stripe_data_session_id;
}

.user_card__stripe_data_payment_method_id {
    grid-area: stripe_data_payment_method_id;
}
