.user_become_driver {

}

.user_become_driver__apply {
    display: grid;
    grid-template-areas:
            "apply__description apply__description"
            "aggrement aggrement"
            "apply__apply apply__not_interested";
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    max-width: 400px;
}

.user_become_driver__apply__description {
    grid-area: apply__description;
}

.user_become_driver__agreement {
    grid-area: aggrement;
    display: flex;
    flex-direction: column;
}

.user_become_driver__apply__apply {
    grid-area: apply__apply;

}

.user_become_driver__apply__not_interested {
    grid-area: apply__not_interested;
}

.user_become_driver__divider__common_info {
    padding-bottom: 1em;
}

.user_become_driver__continue {
    display: grid;
    grid-template-areas:
            "continue__description continue__description"
            "continue__setup .";
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    max-width: 600px;
}

.user_become_driver__continue__description {
    grid-area: continue__description
}

.user_become_driver__continue__setup {
    grid-area: continue__setup;
}

/* Mobile view */
@media (max-width: 600px) {
    .user_become_driver__apply {
        grid-template-columns: 1fr;
        grid-template-areas:
        "apply__description"
        "apply__apply"
        "apply__not_interested"
    }

    .user_become_driver__continue {
        grid-template-columns: 1fr;
        grid-template-areas:
        "continue__description"
        "continue__setup"
    }
}
