.order_track_card {
    margin-top: 1em;
}

.order_track_card__fields {
    display: grid;
    grid-template-areas:
        "divider__timeline divider__timeline"
        "timeline timeline"

        "divider__short_info divider__short_info"
        "pickup_from_short delivery_to_short"
        "pickup_at_calculated delivery_at_calculated"
        "packages order_id"

        "typography_not_found typography_not_found";

    grid-template-columns: 1fr 1fr;

    max-width: 1200px;
    margin: auto;
    align-items: center;
    row-gap: 1em;
    column-gap: 1em;
    align-content: center;
}

/* Mobile view */
@media (max-width: 600px) {
    .order_track_card__fields {
        grid-template-columns: 1fr;
        grid-template-areas:
        "divider__timeline"
        "timeline"

        "divider__short_info"
        "pickup_from_short"
        "pickup_at_calculated"

        "delivery_to_short"
        "delivery_at_calculated"

        "packages"
        "order_id"

        "typography_not_found";
    }
}

.order_track_card__divider__timeline {
    grid-area: divider__timeline;
}

.order_timeline {
    padding-top: 0.7em;
    grid-area: timeline
}

.order_track_card__divider__short_info {
    grid-area: divider__short_info
}

.order_track_card__pickup_from_short {
    grid-area: pickup_from_short
}

.order_track_card__pickup_at_calculated {
    grid-area: pickup_at_calculated
}

.order_track_card__delivery_to_short {
    grid-area: delivery_to_short
}

.order_track_card__delivery_at_calculated {
    grid-area: delivery_at_calculated
}

.order_track_card__packages {
    grid-area: packages
}

.order_track_card__order_id {
    grid-area: order_id
}


.order_track_card__typography_not_found {
    grid-area: typography_not_found
}
