.elements_table__container {
    width: 100%;
    overflow-x: auto;
    max-width: max-content;
}

table.elements_table__table {
    width: max-content;
    display: table;
}

th.elements_table__table_cell__head {
    background-color: #42a5f5;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
}

td.elements_table__table_cell__body {
    text-align: center;
}

.elements_table__add_new {
    padding-top: 0.5em;
}

/* Mobile view */
@media (max-width: 600px) {
    .elements_table__container {
        max-width: 91vw;
    }
}
