.order_card__divider__pickup_info {
    grid-area: divider__pickup_info;
}

.order_card__pickup_from {
    grid-area: pickup_from;
}

.order_card__pickup_contactless {
    grid-area: pickup_contactless;
}

.order_card__pickup_phone_number {
    grid-area: pickup_phone_number;
}

.order_card__pickup_at_input {
    grid-area: pickup_at_input;
}

.order_card__pickup_at_calculated {
    grid-area: pickup_at_calculated;
}
