.pdf_viewer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    grid-area: pdf_viewer;
}

/* Mobile view */
@media (max-width: 600px) {
    .pdf_viewer {
        overflow-x: auto;
        overflow-y: auto;
    }
}

.pdf_container {
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100vh;
}

.pdf_document {
    background-color: #f5f5f5;
}

.pdf_page {
    margin: 20px;
}

.pdf_viewer__top_navigation {
    display: flex;
    justify-content: center;
    padding-bottom: 1em;
}

.pdf_viewer__bottom_navigation {
    display: flex;
    justify-content: center;
    padding-top: 1em;
}
