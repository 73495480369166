.agreement_card__divider__document_info {
    grid-area: divider__document_info;
}

.agreement_card__name {
    grid-area: name;
}

.agreement_card__number {
    grid-area: number;
}

.agreement_card__effective_date {
    grid-area: effective_date;
}

.agreement_card__download_pdf {
    grid-area: download_pdf;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1em;
}