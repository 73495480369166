.admin_user_card__divider__admin_actions {
    grid-area: divider__admin_actions;
}

.admin_user_card__user_driver_status_action_buttons {
    grid-area: user_driver_status_action_buttons;
    display: flex;
    gap: 10px;
    justify-content: center;
    text-align: center;
}

.admin_user_card__user_driver_status_action_buttons__text {
    align-self: center;
}
