.order_card__divider__payment_info {
    grid-area: divider__payment_info;
}

.order_card__customer_id {
    grid-area: customer_id;
}

.order_card__payment_method_id {
    grid-area: payment_method_id;
}

.order_card__payment_intent_id {
    grid-area: payment_intent_id;
}
