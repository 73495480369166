.admin_user_card__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "divider__admin_actions divider__admin_actions"
        "user_driver_status_action_buttons user_driver_status_action_buttons"

        "divider__user_info divider__user_info"
        "name phone_number"
        "driver_status email"

        "divider__basic_payment_info divider__basic_payment_info"
        "stripe_data_customer_id stripe_data_session_id"
        "stripe_data_payment_method_id ."

        "divider__basic_info divider__basic_info"
        "user_id ."
        "created_at updated_at"
        "role selected_role"
        "save delete_stripe_connect_account";
    grid-auto-flow: dense;

    max-width: 1200px;
    margin: auto;
    align-items: center;
    row-gap: 1em;
    column-gap: 1em;
}

/* Mobile view */
@media (max-width: 600px) {
    .admin_user_card__fields {
        grid-template-columns: 1fr;
        grid-template-areas:
            "save"

            "divider__user_info"
            "name"
            "phone_number"
            "driver_status"
            "email"

            "divider__admin_actions"
            "user_driver_status_action_buttons"


            "divider__basic_payment_info"
            "stripe_data_customer_id"
            "stripe_data_session_id"
            "stripe_data_payment_method_id"

            "divider__basic_info"
            "user_id"
            "created_at"
            "updated_at"
            "role"
            "selected_role"

            "delete_stripe_connect_account";
    }
}


.admin_user_card__save {
    grid-area: save;
}

.admin_user_card__delete_stripe_connect_account {
    grid-area: delete_stripe_connect_account;
}