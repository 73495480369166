.user_card__divider__user_info {
    grid-area: divider__user_info;
}

.user_card__name {
    grid-area: name;
}

.user_card__phone_number {
    grid-area: phone_number;
}

.user_card__driver_status {
    grid-area: driver_status;
}

.user_card__email {
    grid-area: email;
}
