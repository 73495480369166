.agreement_card__divider__file_info {
    grid-area: divider__file_info;
}

.agreement_card__file_name {
    grid-area: file_name;
}

.agreement_card__mime_type {
    grid-area: mime_type;
}

.agreement_card__extension {
    grid-area: extension;
}

.agreement_card__size_bytes {
    grid-area: size_bytes;
}
