.about_us {
    display: grid;
    grid-template-columns: min-content 1fr 2fr;
    grid-template-areas:
        "how_it_works__title how_it_works__title how_it_works__video"
        ". . how_it_works__video"
        "how_it_works__number_1 how_it_works__text_1 how_it_works__video"
        "how_it_works__number_2 how_it_works__text_2 how_it_works__video"
        "how_it_works__number_3 how_it_works__text_3 how_it_works__video"
        "how_it_works__text_4 how_it_works__text_4 how_it_works__video"
        ". . how_it_works__video"
        ". . how_it_works__video"
        ". . how_it_works__video"
        "divider__description divider__description divider__description"
        "description description description";
    row-gap: 1em;
    column-gap: 1em;
    max-width: 1200px;
    margin: auto;

    align-items: center;
    padding-top: 1em;
}

/* Mobile view */
@media (max-width: 600px) {
    .about_us {
        grid-template-columns: 2fr;
        grid-template-areas:
            "how_it_works__video how_it_works__video"
            "how_it_works__title how_it_works__title"
            "how_it_works__number_1 how_it_works__text_1"
            "how_it_works__number_2 how_it_works__text_2"
            "how_it_works__number_3 how_it_works__text_3"
            "how_it_works__number_4 how_it_works__text_4"
            "divider__description divider__description"
            "description description";
    }
}

.about_us__how_it_works__title {
    grid-area: how_it_works__title;
}

.about_us__how_it_works__number_1 {
    width: 36px;
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    color: #1976d2;
    border: 2px solid #1976d2;
    border-radius: 50%;
    text-align: center;

    grid-area: how_it_works__number_1;
}

.about_us__how_it_works__number_2 {
    width: 36px;
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    color: #1976d2;
    border: 2px solid #1976d2;
    border-radius: 50%;
    text-align: center;

    grid-area: how_it_works__number_2;
}

.about_us__how_it_works__number_3 {
    width: 36px;
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    color: #1976d2;
    border: 2px solid #1976d2;
    border-radius: 50%;
    text-align: center;

    grid-area: how_it_works__number_3;
}

.about_us__how_it_works__number_4 {
    width: 36px;
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    color: #1976d2;
    border: 2px solid #1976d2;
    border-radius: 50%;
    text-align: center;

    grid-area: how_it_works__number_4;
}

.about_us__how_it_works__text_1 {
    grid-area: how_it_works__text_1;
}

.about_us__how_it_works__text_2 {
    grid-area: how_it_works__text_2;
}

.about_us__how_it_works__text_3 {
    grid-area: how_it_works__text_3;
}

.about_us__how_it_works__text_4 {
    grid-area: how_it_works__text_4;
}

.about_us__how_it_works__video {
    grid-area: how_it_works__video;
}

.about_us__divider__description {
    grid-area: divider__description;
}

.about_us__description {
    grid-area: description;

    text-align: justify;
    text-indent: 3em
}
