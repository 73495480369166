.user_profile {
    margin-top: 1em;
}

.user_profile__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "divider__common_info divider__common_info"
        "accept not_interested"

        "user_become_manager user_become_manager"

        "divider__user_info divider__user_info"
        "name phone_number"
        "driver_status email"

        "divider__basic_payment_info divider__basic_payment_info"
        "stripe_data_customer_id stripe_data_session_id"
        "stripe_data_payment_method_id ."

        "divider__basic_info divider__basic_info"
        "user_id ."
        "created_at updated_at"
        "role selected_role"
        "save .";

    max-width: 1200px;
    margin: auto;
    align-items: center;
    row-gap: 1em;
    column-gap: 1em;
}

/* Mobile view */
@media (max-width: 600px) {
    .user_profile__fields {
        grid-template-columns: 1fr;
        grid-template-areas:
            "divider__common_info"

            "save"
            "accept"
            "not_interested"

            "divider__user_info"
            "name"
            "phone_number"
            "driver_status"
            "email"

            "divider__basic_payment_info"
            "stripe_data_customer_id"
            "stripe_data_session_id"
            "stripe_data_payment_method_id"

            "divider__basic_info"
            "user_id"
            "created_at"
            "updated_at"
            "role"
            "selected_role";
    }
}

.user_profile__save {
    grid-area: save;
}

.user_become_manager {
    grid-area: user_become_manager;
}
