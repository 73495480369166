.admin_rate_card__fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
        "divider__rate_overview divider__rate_overview divider__rate_overview divider__rate_overview divider__rate_overview divider__rate_overview"
        "value max_distance_mi max_length_in max_width_in max_height_in max_weight_lb"

        "divider__basic_info divider__basic_info divider__basic_info divider__basic_info divider__basic_info divider__basic_info"
        "rate_id rate_type created_at created_at updated_at updated_at"
        "save save delete delete . .";

    max-width: 1200px;
    margin: auto;
    align-items: center;
    row-gap: 1em;
    column-gap: 1em;
}

/* Mobile view */
@media (max-width: 600px) {
    .admin_rate_card__fields {
        grid-template-columns: 1fr;
        grid-template-areas:
        "save"
        "divider__rate_overview"
        "value"
        "max_distance_mi"
        "max_length_in"
        "max_width_in"
        "max_height_in"
        "max_weight_lb"

        "divider__basic_info"
        "rate_id"
        "rate_type"
        "created_at"
        "updated_at"
        "delete";
    }
}

.admin_rate_card__divider__rate_overview {
    grid-area: divider__rate_overview;
}

.admin_rate_card__value {
    grid-area: value;
}

.admin_rate_card__rate_type {
    grid-area: rate_type;
}

.admin_rate_card__max_distance_mi {
    grid-area: max_distance_mi;
}

.admin_rate_card__max_length_in {
    grid-area: max_length_in;
}

.admin_rate_card__max_width_in {
    grid-area: max_width_in;
}

.admin_rate_card__max_height_in {
    grid-area: max_height_in;
}

.admin_rate_card__max_weight_lb {
    grid-area: max_weight_lb;
}


.admin_rate_card__divider__basic_info {
    grid-area: divider__basic_info;
}

.admin_rate_card__rate_id {
    grid-area: rate_id;
}

.admin_rate_card__created_at {
    grid-area: created_at;
}

.admin_rate_card__updated_at {
    grid-area: updated_at;
}


.admin_rate_card__save {
    grid-area: save;
}

.admin_rate_card__delete {
    grid-area: delete;
}
