.help_page {
    max-width: 1300px;
    margin: auto;
    padding-top: 2em;

    display: flex;
    justify-content: center;
}

p.help_page__description {
    font-weight: bold;
}
