.image_input {

}

.image_input__preview {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

.image_input__upload_button {
    padding-top: 0.5em;
}
