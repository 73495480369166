.order_tracks {
    padding-top: 1em;
    max-width: 1200px;
}

.order_tracks__fields {
    display: grid;
    grid-template-areas:
        "search"
        "order_track_card";
    grid-template-columns: 1fr;
    margin: auto;
    align-items: center;
    row-gap: 1em;
    column-gap: 1em;
}

/* Mobile view */
@media (max-width: 600px) {
    .order_tracks__fields {
        grid-template-columns: 1fr;
        grid-template-areas:
            "search"
            "order_track_card";
    }

    .order_tracks__search {
        flex-direction: column;
    }
}


.order_tracks__search {
    grid-area: search;
    display: flex;
    row-gap: 1em;
    justify-content: center;
    align-items: center;
    column-gap: 1em;
}

.order_tracks__order_track_card {
    grid-area: order_track_card;
}


.order_tracks__order_id {
    width: 200px;
}

p.order_tracks__title {
    font-weight: bold;
}

.order_tracks__search_button {
    width: max-content;
}

.order_tracks__order_id_and_search_button {
    display: flex;
    column-gap: 0.5em;
    align-items: center;
}
