.driver_dashboard__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "driver_map driver_map"
        "orders_table orders_table";
    max-width: 1200px;
    margin: auto;
    align-items: center;
    row-gap: 1em;
    column-gap: 1em;
}

/* Mobile view */
@media (max-width: 600px) {
    .driver_dashboard__fields {
        grid-template-columns: 1fr;
        grid-template-areas:
            "driver_map"
            "orders_table"
    }
}


.driver_map {
    grid-area: driver_map;
}

.driver_dashboard__orders_table {
    grid-area: orders_table;
}
