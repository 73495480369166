.login_page {
    max-width: 400px;
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1em;
    padding-top: 1em;
}

.login_page__email {
    width: 100%;
    max-width: 300px;
}

.login_page__divider {
    margin: auto;
    align-self: center;
}
