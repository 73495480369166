.agreement_card__divider__status_info {
    grid-area: divider__status_info;
}

.agreement_card__status {
    grid-area: status;
}

.agreement_card__user_role {
    grid-area: user_role;
}
