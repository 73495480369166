.driver_route_create__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "divider__create_a_route divider__create_a_route"
        "description description"
        "pickup_from delivery_to"
        "pickup_at delivery_at"
        "buffer_distance_mi ."
        "submit .";

    max-width: 1200px;
    margin: auto;
    align-items: center;
    row-gap: 1em;
    column-gap: 1em;
}

/* Mobile view */
@media (max-width: 600px) {
    .driver_route_create__fields {
        grid-template-columns: 1fr;
        grid-template-areas:
            "divider__create_a_route"
            "description"
            "pickup_from"
            "pickup_at"
            "delivery_to"
            "delivery_at"
            "buffer_distance_mi"
            "submit";
    }
}

.driver_route_create__divider__create_a_route {
    grid-area: divider__create_a_route;
}

.driver_route_create__description {
    grid-area: description;
    display: flex;
    column-gap: 0.5em;
}

.driver_route_create__pickup_from {
    grid-area: pickup_from;
    z-index: 2;
}

.driver_route_create__pickup_at {
    grid-area: pickup_at;
}

.driver_route_create__delivery_to {
    grid-area: delivery_to;
    z-index: 2;
}

.driver_route_create__delivery_at {
    grid-area: delivery_at;
}

.driver_route_create__buffer_distance_mi {
    grid-area: buffer_distance_mi;
    padding-top: 8px;
}

.driver_route_create__submit {
    grid-area: submit;
}
