.auth_otp_page {
    max-width: 400px;
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1em;
    padding-top: 1em;
}
