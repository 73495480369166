.order_card__divider__driver_info {
    grid-area: divider__driver_info;
}

.order_card__driver_id {
    grid-area: driver_id;
}

.order_card__driver_phone_number {
    grid-area: driver_phone_number;
}

.order_card__driver_name {
    grid-area: driver_name;
}
