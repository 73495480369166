.order_card__divider__delivery_info {
    grid-area: divider__delivery_info;
}

.order_card__delivery_to {
    grid-area: delivery_to;
}

.order_card__delivery_at_input {
    grid-area: delivery_at_input;
}

.order_card__delivery_at_calculated {
    grid-area: delivery_at_calculated;
}

.order_card__delivery_contactless {
    grid-area: delivery_contactless;
}

.order_card__delivery_phone_number {
    grid-area: delivery_phone_number;
}
