.admin_rate_create__fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
        "divider__rate_overview divider__rate_overview divider__rate_overview divider__rate_overview divider__rate_overview divider__rate_overview divider__rate_overview"
        "rate_type value max_distance_mi max_length_in max_width_in max_height_in max_weight_lb"
        "save save . . . . .";

    max-width: 1200px;
    margin: auto;
    align-items: center;
    row-gap: 1em;
    column-gap: 1em;
}

/* Mobile view */
@media (max-width: 600px) {
    .admin_rate_create__fields {
        grid-template-columns: 1fr;
        grid-template-areas:
        "divider__rate_overview"
        "rate_type"
        "value"
        "max_distance_mi"
        "max_length_in"
        "max_width_in"
        "max_height_in"
        "max_weight_lb"
        "save";
    }
}

.admin_rate_create__divider__rate_overview {
    grid-area: divider__rate_overview;
}

.admin_rate_create__value {
    grid-area: value;
}

.admin_rate_create__rate_type {
    grid-area: rate_type;
}

.admin_rate_create__max_distance_mi {
    grid-area: max_distance_mi;
}

.admin_rate_create__max_length_in {
    grid-area: max_length_in;
}

.admin_rate_create__max_width_in {
    grid-area: max_width_in;
}

.admin_rate_create__max_height_in {
    grid-area: max_height_in;
}

.admin_rate_create__max_weight_lb {
    grid-area: max_weight_lb;
}

.admin_rate_create__save {
    grid-area: save;
}
