.admin_agreement_create__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "divider__overview divider__overview"
        "name number"
        "effective_date user_role"
        "files_image_input ."
        "submit .";

    max-width: 1200px;
    margin: auto;
    align-items: center;
    row-gap: 1em;
    column-gap: 1em;
}

/* Mobile view */
@media (max-width: 600px) {
    .admin_agreement_create__fields {
        grid-template-columns: 1fr;
        grid-template-areas:
            "divider__overview"
            "name"
            "number"
            "effective_date"
            "user_role"
            "files_image_input"
            "submit";
    }
}

.admin_agreement_create__divider__overview {
    grid-area: divider__overview;
}

.admin_agreement_create__name {
    grid-area: name;
}

.admin_agreement_create__number {
    grid-area: number;
}

.admin_agreement_create__effective_date {
    grid-area: effective_date;
}

.admin_agreement_create__user_role {
    grid-area: user_role;
}

.admin_agreement_create__files_image_input {
    grid-area: files_image_input;
}

.admin_agreement_create__submit {
    grid-area: submit;
}
