.order_files_swipeable_view {
    display: flex;
    flex-direction: column;
    row-gap: 1em;

    grid-area: order_files_swipeable_view;
}

.order_files_swipeable_view__carousel {
    max-width: 500px;
    margin: auto;
}

.carousel .thumbs-wrapper {
    /*background-color: red !important; !* Example style *!*/
    margin: 0px !important;
}

ul.thumbs.animated {
    text-align: center;
    margin-bottom: 10px !important;
    padding: 0;
}
