body {
    margin: 0;
    font-family: 'Karla', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    max-width: 100%;
    height: auto;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

main {
    padding: 72px 2em 60px;
}

@media screen and (max-width: 600px) {
    main {
        padding-top: 67px;
        padding-right: 1em;
        padding-left: 1em;
    }
}

input::file-selector-button {
    cursor: pointer;
    background-color: #1976d2;
    color: white;
    font-family: inherit;
    padding: 0.8em;
    border: none;
    border-radius: 4pt;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

input::file-selector-button:hover {
    background-color: #1565c0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

input::file-selector-button:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.flex-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
}

.footer {
    margin-top: auto;
}

#react-select-3-listbox {
    z-index: 2;
}

#react-select-9-listbox {
    z-index: 2;
}
