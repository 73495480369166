.order_card__divider__contact_info {
    grid-area: divider__contact_info;
}

.order_card__email {
    grid-area: email;
}

.order_card__phone_number {
    grid-area: phone_number;
}

.order_card__comment {
    grid-area: comment;
}
