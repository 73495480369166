.user_request_missing_info {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

.user_request_missing_info__message {

}

.user_request_missing_info__name {

}

.user_request_missing_info__phone_number {

}
