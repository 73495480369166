.route_card__divider__basic_info {
    grid-area: divider__basic_info;
}

.route_card__route_id {
    grid-area: route_id;
}

.route_card__user_id {
    grid-area: user_id;
}

.route_card__created_at {
    grid-area: created_at;
}

.route_card__updated_at {
    grid-area: updated_at;
}

.route_card__buffer_distance_miles {
    grid-area: buffer_distance_miles;
}
