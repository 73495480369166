.user_order_create_v2 {
    margin-top: 1em;
}

.block {
    transition: opacity 0.7s ease;
    opacity: 0;
}

.block.visible {
    opacity: 1;
}

#button1 {
    text-transform: none
}

.svg-container {
    max-height: 110px; /* Set your desired height */
    max-width: 110px; /* This can be adjusted or set a fixed width if needed */
}

.svg-container svg {
    height: 100%;
    width: 100%;
}