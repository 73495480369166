.user_dashboard__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "divider__current_order divider__current_order"
        "order_short_details_with_timeline order_short_details_with_timeline"
        "map map"
        "divider__orders divider__orders"
        "orders_table orders_table";
    max-width: 1200px;
    margin: auto;
    align-items: center;
    row-gap: 1em;
    column-gap: 1em;
}

/* Mobile view */
@media (max-width: 600px) {
    .user_dashboard__fields {
        grid-template-columns: 1fr;
        grid-template-areas:
            "divider__current_order"
            "order_short_details_with_timeline"
            "map"
            "divider__orders"
            "orders_table"
    }
}

.user_dashboard__divider__current_order {
    grid-area: divider__current_order;
}

.user_dashboard__order_short_details_with_timeline {
    grid-area: order_short_details_with_timeline;
}

.user_dashboard__map {
    grid-area: map;
}

.user_dashboard__divider__orders {
    grid-area: divider__orders;
}

.user_orders_table {
    grid-area: orders_table;
}
