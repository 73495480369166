.agreement_card__divider__base_info {
    grid-area: divider__base_info;
}

.agreement_card__created_at {
    grid-area: created_at;
}

.agreement_card__updated_at {
    grid-area: updated_at;
}

.agreement_card__user_id {
    grid-area: user_id;
}
