.order_timeline {
    align-self: center;
    width: 100%;
    justify-self: center;
}

@media screen and (max-width: 600px) {
    .order_timeline {
        width: auto;
        max-width: 140px;
    }
}
