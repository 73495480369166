.user_order_card__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "divider__overview divider__overview"
        "short_details_with_timeline short_details_with_timeline"
        "map map"
        "order_files_swipeable_view order_files_swipeable_view"

        "divider__driver_info divider__driver_info"
        "driver_name driver_phone_number"

        "divider__pickup_info divider__pickup_info"
        "pickup_from ."
        "pickup_contactless pickup_phone_number"
        "pickup_at_calculated pickup_at_input"

        "divider__delivery_info divider__delivery_info"
        "delivery_to ."
        "delivery_contactless delivery_phone_number"
        "delivery_at_calculated delivery_at_input"

        "divider__route_info divider__route_info"
        "distance_mi duration_seconds"

        "divider__package_info divider__package_info"
        "packages ."

        "divider__payment_info divider__payment_info"
        "customer_id payment_method_id"
        "payment_intent_id ."

        "divider__contact_info divider__contact_info"
        "email phone_number"
        "comment ."

        "divider__base_info divider__base_info"
        "created_at updated_at"
        "user_id status";

    max-width: 1200px;
    margin: auto;
    align-items: center;
    row-gap: 1em;
    column-gap: 1em;
}

/* Mobile view */
@media (max-width: 600px) {
    .user_order_card__fields {
        grid-template-columns: 1fr;
        grid-template-areas:
            "divider__overview"
            "short_details_with_timeline"
            "map"
            "order_files_swipeable_view"

            "divider__driver_info"
            "driver_id"
            "driver_phone_number"
            "driver_name"

            "divider__pickup_info"
            "pickup_from"
            "pickup_contactless"
            "pickup_phone_number"
            "pickup_at_calculated"
            "pickup_at_input"

            "divider__delivery_info"
            "delivery_to"
            "delivery_contactless"
            "delivery_phone_number"
            "delivery_at_calculated"
            "delivery_at_input"

            "divider__route_info"
            "distance_mi"
            "duration_seconds"

            "divider__package_info"
            "packages"

            "divider__payment_info"
            "customer_id"
            "payment_method_id"
            "payment_intent_id"

            "divider__contact_info"
            "email"
            "phone_number"
            "comment"

            "divider__base_info"
            "created_at"
            "updated_at"
            "user_id"
            "status";
    }
}

.user_order_card__divider__overview {
    grid-area: divider__overview;
}

.user_order_card__short_details_with_timeline {
    grid-area: short_details_with_timeline;
}

.user_order_card__map {
    grid-area: map;
}
