.order_short_details_with_timeline {
    grid-area: order_short_details_with_timeline;

    display: grid;
    row-gap: 1em;
    grid-template-columns: max-content 1fr;
    grid-template-areas:
        "card timeline"
        "user_order_action_block user_order_action_block";
}

.user_order_action_block {
    grid-area: user_order_action_block;
    justify-self: start;

    @media screen and (max-width: 600px) {
        justify-self: center;
    }
}

@media screen and (max-width: 600px) {
    .order_short_details_with_timeline {
        grid-template-columns: 1fr;
        grid-template-areas:
        "card"
        "user_order_action_block"
        "timeline";
    }
}


.order_short_details_with_timeline__card {
    width: max-content;
    margin: auto;
    min-width: 178px;

    grid-area: card;
}

.order_short_details_with_timeline__card__id {
    display: flex;
    align-items: center;
    column-gap: 0.2em;
}

.order_timeline {
    grid-area: timeline;
}
