.order_card__divider__route_info {
    grid-area: divider__route_info;
}

.order_card__distance_mi {
    grid-area: distance_mi;
}

.order_card__duration_seconds {
    grid-area: duration_seconds;
}
