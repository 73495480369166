.order_card__divider__base_info {
    grid-area: divider__base_info;
}

.order_card__created_at {
    grid-area: created_at;
}

.order_card__updated_at {
    grid-area: updated_at;
}

.order_card__user_id {
    grid-area: user_id;
}

.order_card__status {
    grid-area: status;
}
