.user_card__divider__basic_info {
    grid-area: divider__basic_info;
}

.user_card__user_id {
    grid-area: user_id;
}

.user_card__role {
    grid-area: role;
}

.user_card__selected_role {
    grid-area: selected_role;
}

.user_card__created_at {
    grid-area: created_at;
}

.user_card__updated_at {
    grid-area: updated_at;
}