footer {
    margin-top: auto;
    background-color: #1976d2;
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer__text {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
    text-decoration: none;
}
