.admin_rate_calculate__fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
        "divider__calculator divider__calculator divider__calculator divider__calculator divider__calculator divider__calculator divider__calculator divider__calculator"
        "rate_type distance_mi weight_lb length_in width_in height_in rate calculate";

    max-width: 1200px;
    margin: auto;
    align-items: center;
    row-gap: 1em;
    column-gap: 1em;
}

/* Mobile view */
@media (max-width: 600px) {
    .admin_rate_calculate__fields {
        grid-template-columns: 1fr;
        grid-template-areas:
        "divider__calculator"
        "rate_type"
        "distance_mi"
        "weight_lb"
        "length_in"
        "width_in"
        "height_in"
        "rate"
        "calculate";
    }
}

.admin_rate_calculate__divider__calculator {
    grid-area: divider__calculator;
}

.admin_rate_calculate__rate_type {
    grid-area: rate_type;
}

.admin_rate_calculate__distance_mi {
    grid-area: distance_mi;
}

.admin_rate_calculate__weight_lb {
    grid-area: weight_lb;
}

.admin_rate_calculate__length_in {
    grid-area: length_in;
}

.admin_rate_calculate__width_in {
    grid-area: width_in;
}

.admin_rate_calculate__height_in {
    grid-area: height_in;
}

.admin_rate_calculate__rate {
    grid-area: rate;
}

.admin_rate_calculate__calculate {
    grid-area: calculate;
}
