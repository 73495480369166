.route_card__divider__route_info {
    grid-area: divider__route_info;
}

.route_card__start_at_utc {
    grid-area: start_at_utc;
}

.route_card__end_at_utc {
    grid-area: end_at_utc;
}

.route_card__distance_data_distance_mi {
    grid-area: distance_data_distance_mi;
}

.route_card__distance_data_duration_seconds {
    grid-area: distance_data_duration_seconds;
}
