.manager_agreement_card__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "divider__overview divider__overview"
        "divider__document_info divider__document_info"
        "name number"
        "effective_date ."

        "divider__document_view divider__document_view"
        "pdf_viewer pdf_viewer"
        "download_pdf download_pdf";

    max-width: 1200px;
    margin: auto;
    align-items: center;
    row-gap: 1em;
    column-gap: 1em;
}

/* Mobile view */
@media (max-width: 600px) {
    .manager_agreement_card__fields {
        grid-template-columns: 1fr;
        grid-template-areas:
            "divider__overview"
            "divider__document_info"
            "name"
            "number"
            "effective_date"

            "divider__document_view"
            "pdf_viewer"
            "download_pdf";
    }
}

.manager_agreement_card__divider__overview {
    grid-area: divider__overview;
}
