.admin_route_card__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "divider__overview divider__overview"
        "map map"

        "divider__route_info divider__route_info"
        "start_at_utc end_at_utc"
        "distance_data_distance_mi distance_data_duration_seconds"

        "divider__stops_details divider__stops_details"
        "stops_details_table stops_details_table"

        "divider__basic_info divider__basic_info"
        "route_id user_id"
        "created_at updated_at"
        "buffer_distance_miles .";

    max-width: 1200px;
    margin: auto;
    align-items: center;
    row-gap: 1em;
    column-gap: 1em;
}

/* Mobile view */
@media (max-width: 600px) {
    .admin_route_card__fields {
        grid-template-columns: 1fr;
        grid-template-areas:
        "divider__overview"
        "map"

        "divider__route_info"
        "start_at_utc"
        "end_at_utc"
        "distance_data_distance_mi"
        "distance_data_duration_seconds"

        "divider__stops_details"
        "stops_details_table"

        "divider__basic_info"
        "route_id"
        "user_id"
        "created_at"
        "updated_at"
        "buffer_distance_miles"
    }
}

.admin_route_card__divider__overview {
    grid-area: divider__overview;
}

.admin_route_card__map {
    grid-area: map;
}

.elements_table__container {
    grid-area: stops_details_table;
}
