.admin_agreement_card__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "divider__overview divider__overview"
        "divider__document_info divider__document_info"
        "name number"
        "effective_date ."

        "divider__status_info divider__status_info"
        "status user_role"
        "action_buttons ."

        "divider__file_info divider__file_info"
        "file_name mime_type"
        "extension size_bytes"

        "divider__base_info divider__base_info"
        "created_at updated_at"
        "user_id agreement_status"

        "divider__document_view divider__document_view"
        "pdf_viewer pdf_viewer"
        "download_pdf download_pdf"
        "delete save";

    max-width: 1200px;
    margin: auto;
    align-items: center;
    row-gap: 1em;
    column-gap: 1em;
}

/* Mobile view */
@media (max-width: 600px) {
    .admin_agreement_card__fields {
        grid-template-columns: 1fr;
        grid-template-areas:
            "save"
            "divider__overview"
            "divider__document_info"
            "name"
            "number"
            "effective_date"

            "divider__status_info"
            "user_role"
            "status"
            "action_buttons"

            "divider__file_info"
            "file_name"
            "mime_type"
            "extension"
            "size_bytes"

            "divider__base_info"
            "created_at"
            "updated_at"
            "user_id"
            "agreement_status"

            "divider__document_view"
            "pdf_viewer"
            "download_pdf"
            "delete";
    }
}

.admin_agreement_card__divider__overview {
    grid-area: divider__overview;
}

.admin_agreement_card__action_buttons {
    grid-area: action_buttons;
    display: flex;
    column-gap: 1em;
}

.admin_agreement_card__delete {
    grid-area: delete;
}

.admin_agreement_card__save {
    grid-area: save;
}
